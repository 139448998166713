export const apis = {
    //Login
    LOGIN: 'auth/login',
    GET_CURRENT_LOGGED_IN_USER: 'auth/current-user',
    LOGOUT: 'auth/logout',
    MEMBER_REGISTER: 'auth/register',
    ADMIN_REGISTER: 'auth/admin/register',
    ADMIN_COMPLETE_RESISTRATION: 'auth/admin/complete/profile',
    // FORGOT_PASSWORD: 'auth/forget-password',
    // RESET_PASSWORD: 'auth/reset-password',
    
    // Admin
    ADMIN_REGISTER_TWO: 'admin/add',
};