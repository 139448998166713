// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../../../router/index";

const state = {
    // Additional state if needed
    getNotice: '',
    noticeById: '',
}

const mutations = {
    SET_NOTICE_DATA(state, data) {
        state.getNotice = data
    },
    SET_NOTICE_BY_ID(state, data) {
        state.noticeById = data
    },
}

const actions = {
    // Additional actions if needed
      getNotice({ commit }, id) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_NOTICE_ADMIN_ID + id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                            "ngrok-skip-browser-warning": "69420",
                        },
                    })
                    .then((res) => {
                        console.log('getNotice---res !!', res)
                        if(Array.isArray(res.data.data)){
                            commit('SET_NOTICE_DATA', res.data.data)
                        }
                        else{
                           commit('SET_NOTICE_DATA', res.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('getNotice err', err)
                    })
            // })
    },

    getNoticeById({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_NOTICE_BY_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getNoticeById---res !!', res)
                    if(res.data.data){
                        commit('SET_NOTICE_BY_ID', res.data.data)
                    }
                    else{
                       commit('SET_NOTICE_BY_ID', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getNoticeById err', err)
                })
        // })
    },

    addNotice({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("topic", payload.topic);
        formData.append("message", payload.message);
        formData.append("file", payload.file);
        formData.append("userId", payload.userId);
        formData.append("adminId", payload.adminId);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_NOTICE, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addNotice res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/all-enquiries")
                    dispatch('getNotice', payload.adminId)
                })
                .catch((err) => {
                    console.log("addNotice err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    editNotice({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("topic", payload.topic);
        formData.append("message", payload.message);
        formData.append("file", payload.file);
        
        return new Promise((resolve, reject) => {
            axios
                .patch(axios.defaults.baseURL + apis.EDIT_NOTICE_BY_ID + payload.id, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("editNotice res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/my-enquiries")
                    dispatch('getNotice', payload.adminId)
                })
                .catch((err) => {
                    console.log("editNotice err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },


    deleteNoticeById({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_NOTICE_BY_ID + payload.id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deleteNoticeById---res !!', res)
                    resolve(res)
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getNotice', payload.adminId)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteNoticeById err', err)
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                })
        })
    },


    // Comments Apis
    addComment({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("comment", payload.comment);
        formData.append("userId", payload.userId);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_COMMENTS_BY_ID + payload.id, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addComment res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/all-enquiries")
                    dispatch('getNoticeById', payload.id)
                })
                .catch((err) => {
                    console.log("addComment err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    addCommentReply({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("reply", payload.reply);
        formData.append("userId", payload.userId);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_COMMENTS_REPLY_BY_ID + payload.id + "/" + payload.commentId, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addCommentReply res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/all-enquiries")
                    dispatch('getNoticeById', payload.id)
                })
                .catch((err) => {
                    console.log("addCommentReply err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    deleteCommentById({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_COMMENTS_BY_ID + payload.noticeId + "/" + payload.id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deleteCommentById---res !!', res)
                    resolve(res)
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getNoticeById', payload.noticeId)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteCommentById err', err)
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                })
        })
    },

    deleteReplyCommentById({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_COMMENTS_REPLY_BY_ID + payload.noticeId + "/" + payload.id +  "/" + payload.getCmentId, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deleteReplyCommentById---res !!', res)
                    resolve(res)
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getNoticeById', payload.noticeId)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteReplyCommentById err', err)
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                })
        })
    },

    commentLike({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("userId", payload.userId);
        
        return new Promise((resolve, reject) => {
            axios
                .patch(axios.defaults.baseURL + apis.COMMENTS_LIKES_BY_ID + payload.noticeId + "/" + payload.id, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("commentLike res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/my-enquiries")
                    dispatch('getNoticeById', payload.noticeId)
                })
                .catch((err) => {
                    console.log("commentLike err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    commentLikeOnReply({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("userId", payload.userId);
        
        return new Promise((resolve, reject) => {
            axios
                .patch(axios.defaults.baseURL + apis.COMMENTS_REPLY_LIKES_BY_ID + payload.noticeId + "/"+ payload.commentId + "/" + payload.replyCommentId, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("commentLikeOnReply res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/my-enquiries")
                    dispatch('getNoticeById', payload.noticeId)
                })
                .catch((err) => {
                    console.log("commentLikeOnReply err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };