export const apis = {
    // Member
    GET_MEMBER_BY_ADMIN_ID: 'member/',
    GET_MEMBER_BY_ID: 'member/find/',
    ADD_MEMBER: 'member/add',
    EDIT_MEMBER_BY_ID: 'member/update/',
    DELETE_MEMBER_BY_ID: 'member/delete/',
    CHANGE_STATUS_MEMBER: 'member/status',

    //Notice
    GET_NOTICE_ADMIN_ID: 'notice/id/',
    GET_NOTICE_BY_ID: 'notice/find/',
    ADD_NOTICE: 'notice/create',
    EDIT_NOTICE_BY_ID: 'notice/update/',
    DELETE_NOTICE_BY_ID: 'notice/delete/',

    ADD_COMMENTS_BY_ID: 'notice/add-comment/',
    DELETE_COMMENTS_BY_ID: 'notice/delete-comment/',
    ADD_COMMENTS_REPLY_BY_ID: 'notice/add-replies/',
    DELETE_COMMENTS_REPLY_BY_ID: 'notice/delete-reply/',
    COMMENTS_LIKES_BY_ID: 'notice/like-comment/',
    COMMENTS_REPLY_LIKES_BY_ID: 'notice/like-reply/',

    //Unit
    GET_UNIT_ADMIN_ID: 'unit/',
    GET_UNIT_BY_ID: 'unit/find/',
    ADD_UNIT: 'unit/add',
    EDIT_UNIT_BY_ID: 'unit/update/',
    DELETE_UNIT_BY_ID: 'unit/delete/',

    //Venues
    GET_VUENUES_ADMIN_ID: 'venue/all/',
    GET_VUENUE_BY_ID: 'venue/',
    ADD_VENUE: 'venue/create',
    EDIT_VENUE_BY_ID: 'venue/update/',
    DELETE_VENUE_BY_ID: 'venue/delete/',

    GET_VENUE_BOOKING_FILTER: 'venue/',
    ADD_VENUE_BOOKING: 'venue/booking/',
    GET_VENUE_BOOKINGS: 'venue/bookings/',
    GET_VENUE_USER_BOOKINGS: 'venue/user/bookings/',
    DELETE_VENUE_USER_BOOKINGS: 'venue/',

    //Polling
    GET_POLLS_ADMIN_ID: 'poll/all/',
    GET_POLL_BY_ID: 'poll/',
    ADD_POLL: 'poll/create',
    EDIT_POLL_BY_ID: 'poll/update/',
    DELETE_POLL_BY_ID: 'poll/delete/',
    POLL_VOTES_BY_ID: 'poll/',
     

    //Chat
    GET_GROUPS_BY_ADMIN_ID: 'chat/groups/',
    GET_GROUP_CHATS_BY_ID: 'chat/group/history/',
    GET_GROUP_BY_ID: 'chat/group/find/',
    EDIT_GROUP_BY_ID: 'chat/group/update/',
    CREATE_GROUP: 'chat/group/create',
    JOIN_GROUP: 'chat/group/join',
    LEAVE_GROUP: 'chat/group/leave',
    DELETE_GROUP: 'chat/group/delete/',
    SEND_MESSAGE: 'chat/message/send',
    GET_MESSAGE_INDIVIDUAL: 'chat/friend/',
    SEND_MESSAGE_INDIVIDUAL: 'chat/individual/message/send',
    GET_ALL_CHAT_USERS: 'chat/get/all-users/',

    //Meeting
    GET_MEETINGS_BY_ADMIN_ID: 'meeting/all-meetings/',
    GET_MEETING_BY_ID: 'meeting/',
    CREATE_MEETING: 'meeting/create-meeting',
    EDIT_MEETING_BY_ID: 'meeting/update-meeting/',   
    DELETE_MEETING_BY_ID: 'meeting/delete-meeting/',
    GET_UPCOMING_MEETINGS: 'meeting/upcoming-meetings/',
    GET_PAST_MEETINGS: 'meeting/past-meetings/',
    INVITE_MEMBER_BY_MAIL: 'meeting/invite-members/',
    RESPOND_TO_INVITATION: 'meeting/',
    GET_MEETING_RESPONSES: 'meeting/responses/',
    GET_ALL_ACTIVE_USERS: 'meeting/users/active/',
    ADD_AGENDA_BY_ID: 'meeting/add-agendas/',
    GET_AGENDA_ITEMS: 'meeting/',
    GET_AGENDA_ITEM_BY_ID: 'meeting/',
    ADD_AGENDA_ITEMS: 'meeting/',
    ADD_AGENDA_ITEM_ATTACHMENT: 'meeting/',
    EDIT_AGENDA_ITEMS: 'meeting/',
    DELETE_AGENDA_ITEM: 'meeting/',
    PDF_MEETING_AGENDA: 'meeting/',
    PDF_MEETING_MINUTES: 'meeting/',
    ADD_UPDATE_MINUTES: 'meeting/',
    ADD_ACTION: 'meeting/',
    GET_ACTION: 'meeting/',
    GET_ACTION_BY_ID: 'meeting/',
    DELETE_ACTION_BY_ID: 'meeting/',
    GET_TOPICS: 'meeting/agendas/ids',
    ADD_COMMENT: 'meeting/comments/',
    GET_COMMENT: 'meeting/comments/',
    ASSIGN_AND_SAVE_ACTION: 'meeting/',
    ASSIGN_AND_UPDATE_ACTION_BY_ID: 'meeting/',

    //Pinboard
    GET_PINBOARD_ADMIN_ID: 'pinboard/',
    GET_PINBOARD_BY_ID: 'pinboard/find/',
    ADD_PINBOARD: 'pinboard/add',
    EDIT_PINBOARD_BY_ID: 'pinboard/update/',
    DELETE_PINBOARD_BY_ID: 'pinboard/delete/',

    //Link
    GET_LINK_ADMIN_ID: 'link/all/',
    GET_LINK_BY_ID: 'link/',
    ADD_LINK: 'link/create',
    EDIT_LINK_BY_ID: 'link/update/',
    DELETE_LINK_BY_ID: 'link/delete/',

    //Document
    GET_ALL_FOLDERS: 'file/all-folders/',
    RENAME_FOLDER_BY_ID: 'file/folders/',
    GET_FOLDER_BY_ID: 'file/folders/',
    CREATE_FOLDER: 'file/folders',
    CREATE_DOCUMENT: 'file/documents',
    RENAME_DOCUMENT_BY_ID: 'file/documents/',
    DELETE_FOLDER_BY_ID: 'file/folder/delete/',
    DELETE_DOCUMENT_BY_ID: 'file/documents/delete/',

    //Dashboard
    GET_DASHBOARD: 'admin/dashboard/',
    SWITCH_TO_MEMBER: 'auth/superadmin/login-as-member',
    SWITCH_TO_ADMIN_FROM_MEMBER: 'auth/superadmin/revert-to-admin',

    //Finance
    CREATE_INVOICE: 'invoice/add',

};