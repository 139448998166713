// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/superAdmin';
import router from "../../../router/index";
import store from '@/store';

const state = {
    // Additional state if needed
    getAdmin: '',
}

const mutations = {
    SET_ADMIN_DATA(state, data) {
        state.getAdmin = data
    },
}

const actions = {
    // Additional actions if needed
    getAdmin({ commit }) {
        // return
        // return new Promise((resolve, reject) => {
        axios
            .get(axios.defaults.baseURL + apis.ADMIN, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "ngrok-skip-browser-warning": "69420",
                },
            })
            .then((res) => {
                console.log('getAdmin---res !!', res)
                if (res.data.data) {
                    commit('SET_ADMIN_DATA', res.data.data)
                }
                else {
                    commit('SET_ADMIN_DATA', res.data.message)
                }
            })
            .catch((err) => {
                console.log('getAdmin err', err)
            })
        // })
    },

    switchToAdmin({ dispatch }, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("email", payload.email);

        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.SWITCH_TO_ADMIN, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("switchToAdmin res", res);
                    localStorage.setItem("superAdminToken", res.data.data.oldAccessToken)
                    
                    axios
                        .get(axios.defaults.baseURL + 'auth/current-user', {
                            headers: {
                                Authorization: "Bearer " + res.data.data.accessToken,
                                "ngrok-skip-browser-warning": "69420",
                            },
                        }
                        )
                        .then((response) => {
                            console.log("login details-- auth Swtiching to Admin--", response);
                            localStorage.setItem("token", res.data.data.accessToken);
                            localStorage.setItem("user", JSON.stringify(response.data.data[0]));

                            if(response.data.data[0].role === 'AD'){
                                let user = {
                                    admin: response.data.data[0].admin,
                                    alternativeEmail: response.data.data[0].alternativeEmail,
                                    createdAt: response.data.data[0].createdAt,
                                    email: response.data.data[0].email,
                                    firstName: response.data.data[0].firstName,
                                    profileLink: response.data.data[0].profileLink,
                                    lastName: response.data.data[0].lastName,
                                    member: response.data.data[0].member,
                                    role: response.data.data[0].role,
                                    status: response.data.data[0].status,
                                    subscriptionStatus: response.data.data[0].subscriptionStatus,
                                    updatedAt: response.data.data[0].updatedAt,
                                    _id: response.data.data[0]._id,
                                    token: res.data.data.accessToken,
                                  };
                                  store.commit("SET_LOGIN_DETAILS", user);
                                  store.commit("SET_USER_AUTHS", {isLoggedIn: true, token: res.data.data.accessToken});
                                  store.commit("SET_COMPONENT_LAYOUT", { layout: "AdminLayout" });
                            }

                            if (response.data.data[0].role === 'AD') {
                                router.push("/admin").then(() => {
                                    router.go();
                                });
                            }
    
                            // dispatch("checkErrorAndSendToast", [
                            //     res.data.message,
                            //     "success",
                            // ]);
                        })
                        .catch((err) => {
                            console.log("error ff hai--", err);

                            localStorage.removeItem("token");
                            localStorage.removeItem("user");
                            // router.go();
                        });


                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                })
                .catch((err) => {
                    console.log("switchToAdmin err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },


    BackToSuperAdmin({ dispatch }, payload) {
        console.log("payload--", payload);
        // return

        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.BACK_TO_SUPERADMIN, {}, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("superAdminToken"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("BackToSuperAdmin res", res);
                    
                    axios
                        .get(axios.defaults.baseURL + 'auth/current-user', {
                            headers: {
                                Authorization: "Bearer " + res.data.data.accessToken,
                                "ngrok-skip-browser-warning": "69420",
                            },
                        }
                        )
                        .then((response) => {
                            console.log("login details-- auth Swtiching to Super admin--", response);
                            localStorage.setItem("token", res.data.data.accessToken);
                            localStorage.setItem("user", JSON.stringify(response.data.data[0]));

                            if (response.data.data[0].role[0] === 'SAD') {
                                router.push("/super-admin").then(() => {
                                  router.go();
                                });
                              }
    
                            // dispatch("checkErrorAndSendToast", [
                            //     res.data.message,
                            //     "success",
                            // ]);
                        })
                        .catch((err) => {
                            console.log("error ff hai--", err);

                            localStorage.removeItem("token");
                            localStorage.removeItem("user");
                            // router.go();
                        });


                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                })
                .catch((err) => {
                    console.log("BackToSuperAdmin err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
};