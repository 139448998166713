<template>
     <!-- partial:partials/_navbar.html -->
     <nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
      <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
        <!-- <div class="me-3">
          <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-bs-toggle="minimize">
            <span class="icon-menu"></span>
          </button>
        </div> -->
        <div class="ms-3">
          <router-link class="navbar-brand brand-logo" to="/super-admin">
            <img src="images/logo.png" alt="logo" />
          </router-link>
          <router-link class="navbar-brand brand-logo-mini" to="/super-admin">
            <img src="images/logo-mini.svg" alt="logo" />
          </router-link>
        </div>
      </div>
      <div v-if="User" class="navbar-menu-wrapper d-flex align-items-top">
        <ul class="navbar-nav">
          <li class="nav-item fw-semibold d-none d-lg-block ms-0">
            <div class="d-flex align-items-center">
              <h1 class="welcome-text d-block">{{ greetingMessage }}, <span class="text-black fw-bold">{{User?.firstName +' ' + User?.lastName}}</span></h1>
              <small class="ms-3">
                <strong>Role - {{ typeof User?.role === 'string' ? User?.role : User?.role?.join(', ') }}</strong>
              </small>
            </div>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item"><span class="h5 text-primary">Super Admin</span></li>
          <li class="nav-item dropdown">
            <a class="nav-link count-indicator" id="notificationDropdown" href="#" data-bs-toggle="dropdown">
              <i class="icon-bell"></i>
              <span class="count"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
              aria-labelledby="notificationDropdown">
              <a class="dropdown-item py-3 border-bottom">
                <p class="mb-0 fw-medium float-start">You have 4 new notifications </p>
                <span class="badge badge-pill badge-primary float-end">View all</span>
              </a>
              <a class="dropdown-item preview-item py-3">
                <div class="preview-thumbnail">
                  <i class="mdi mdi-alert m-auto text-primary"></i>
                </div>
                <div class="preview-item-content">
                  <h6 class="preview-subject fw-normal text-dark mb-1">Application Error</h6>
                  <p class="fw-light small-text mb-0"> Just now </p>
                </div>
              </a>
              <a class="dropdown-item preview-item py-3">
                <div class="preview-thumbnail">
                  <i class="mdi mdi-lock-outline m-auto text-primary"></i>
                </div>
                <div class="preview-item-content">
                  <h6 class="preview-subject fw-normal text-dark mb-1">Settings</h6>
                  <p class="fw-light small-text mb-0"> Private message </p>
                </div>
              </a>
              <a class="dropdown-item preview-item py-3">
                <div class="preview-thumbnail">
                  <i class="mdi mdi-airballoon m-auto text-primary"></i>
                </div>
                <div class="preview-item-content">
                  <h6 class="preview-subject fw-normal text-dark mb-1">New user registration</h6>
                  <p class="fw-light small-text mb-0"> 2 days ago </p>
                </div>
              </a>
            </div>
          </li>
          <li class="nav-item dropdown d-none d-lg-block user-dropdown">
            <a class="nav-link" id="UserDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
              <img  class="img-xs rounded-circle" src="images/faces/face8.jpg" alt="Profile image">
              <!-- <img v-if="User?.profileLink != ''" class="img-xs rounded-circle" :src="User?.profileLink" alt="Profile image">  -->
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
              <div class="dropdown-header text-center">
                <!-- <img class="img-md rounded-circle" src="images/faces/face8.jpg" alt="Profile image"> -->
                <p class="mb-1 mt-3 fw-semibold">{{User?.firstName + ' ' + User?.lastName}}</p>
                <p class="fw-light text-muted mb-0">{{User?.email}}</p>
              </div>
              <a class="dropdown-item"><i class="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i> My
                Profile </a>
              <!-- <a class="dropdown-item"><i
                  class="dropdown-item-icon mdi mdi-calendar-check-outline text-primary me-2"></i> Activity</a> -->
              <a @click="logout()" class="dropdown-item">
                <i class="dropdown-item-icon mdi mdi-power text-primary me-2"></i>
                <span>Logout</span>
                <loader v-if="loader" />
              </a>
            </div>
          </li>
        </ul>
        <button class=" ms-2 d-lg-none align-self-center" @click="toggle" type="button">
          <span class="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
    <!-- partial -->
</template>
    
<script>
import loader from '../loader.vue';

export default {
  name: "HeaderPage",
  props: {
    msg: String,
  },

  components: {
        loader
    },

    data(){
        return{
            loader: false,
            currentHour: new Date().getHours(), // Gets the current hour (0-23)
        }
    },

    computed:{
      User(){
        return this.$store.state.auth.user;
      },

      greetingMessage() {
        if (this.currentHour < 12) {
          return "Good Morning";
        } else if (this.currentHour < 18) {
          return "Good Afternoon";
        } else {
          return "Good Evening";
        }
      },
    },

    methods: {
      logout(){
        this.loader = true;
        this.$store.dispatch('logout',{
          userToken: localStorage.getItem('token'),
          name: 'admin',
        })
        .then((res)=>{
            console.log("res", res);
            this.loader = false;
        })
        .catch((err)=>{ 
            console.log("err", err);
            this.loader = false;
        })
      },

      toggle() {
        this.$emit('toggleSidebar');
      },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
                                    