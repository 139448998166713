// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../../../router/index";

const state = {
    // Additional state if needed
    getMembers: '',
    memberById: '',
}

const mutations = {
    SET_MEMBERS_DATA(state, data) {
        state.getMembers = data
    },
    SET_MEMBER_BY_ID(state, data) {
        state.memberById = data
    },
}

const actions = {
    // Additional actions if needed
      getMembers({ commit }, id) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_MEMBER_BY_ADMIN_ID + id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                            "ngrok-skip-browser-warning": "69420",
                        },
                    })
                    .then((res) => {
                        console.log('getMembers---res !!', res)
                        if(Array.isArray(res.data.data)){
                            commit('SET_MEMBERS_DATA', res.data.data)
                        }
                        else{
                           commit('SET_MEMBERS_DATA', res.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('getMembers err', err)
                        // dispatch("checkErrorAndSendToast", [
                        //     err?.response,
                        //     "error",
                        //   ]);
                    })
            // })
    },

    getMemberById({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_MEMBER_BY_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getMemberById---res !!', res)
                    if(Array.isArray(res.data.data)){
                        commit('SET_MEMBER_BY_ID', res.data.data)
                    }
                    else{
                       commit('SET_MEMBER_BY_ID', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getMemberById err', err)
                })
        // })
    },

    addMember({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("adminId", payload.adminId);
        formData.append("firstName", payload.firstName);
        formData.append("lastName", payload.lastName);
        formData.append("profile", payload.profile);
        formData.append("email", payload.email);
        formData.append("role", payload.role);
        formData.append("title", payload.title);
        formData.append("phoneNumbers", payload.phoneNumbers);
        formData.append("address[address1]", payload.address.address1);
        formData.append("address[address2]", payload.address.address2);
        formData.append("address[city]", payload.address.city);
        formData.append("address[state]", payload.address.state);
        formData.append("address[postcode]", payload.address.postcode);
        formData.append("address[country]", payload.address.country);
        formData.append("unitId", payload.unitId);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_MEMBER, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addMember res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/all-enquiries")
                    dispatch('getMembers', payload.adminId)
                })
                .catch((err) => {
                    console.log("addMember err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    editMember({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        // formData.append("adminId", "66e16522b6c2b5013c21414c");
        formData.append("firstName", payload.firstName);
        formData.append("lastName", payload.lastName);
        formData.append("profile", payload.profile);
        formData.append("role", payload.role);
        formData.append("title", payload.title);
        formData.append("phoneNumbers", payload.phoneNumbers);
        formData.append("address[address1]", payload.address.address1);
        formData.append("address[address2]", payload.address.address2);
        formData.append("address[city]", payload.address.city);
        formData.append("address[state]", payload.address.state);
        formData.append("address[postcode]", payload.address.postcode);
        formData.append("address[country]", payload.address.country);
        formData.append("unitId", payload.unitId);
        
        return new Promise((resolve, reject) => {
            axios
                .put(axios.defaults.baseURL + apis.EDIT_MEMBER_BY_ID + payload.id, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("editMember res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/my-enquiries")
                    dispatch('getMembers', payload.adminId)
                })
                .catch((err) => {
                    console.log("editMember err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },


    deleteMemberById({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_MEMBER_BY_ID + payload.id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deleteMemberById---res !!', res)
                    resolve(res)
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getMembers', payload.adminId)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteMemberById err', err)
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                })
        })
    },

    changeStatus({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("userId", payload.userId);
        formData.append("status", payload.status);
        
        return new Promise((resolve, reject) => {
            axios
                .put(axios.defaults.baseURL + apis.CHANGE_STATUS_MEMBER, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("changeStatus res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/all-enquiries")
                    dispatch('getMemberById', payload.userId)
                })
                .catch((err) => {
                    console.log("changeStatus err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };