<template>
    <!-- header starts
============================================= -->
<nav class="navbar navbar-expand-lg navbar-light bg-light" style="padding: 40px 80px;">
    <div class="container-fluid">
    <!-- logo --> 
    <router-link class="logo-default dtr-scroll-link" to="/">
        <img src="assets/imagesLanding/new-logo.png" class="h-auto" style="width: 120px;" alt="logo">
        <!-- Strata Management -->
    </router-link> 
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-md-auto mb-2 mb-lg-0">
          <li class="nav-item"> <a class="nav-link" href="https://projects.dotlinkertech.com/frontend/strata/">Home</a></li>
          <li class="nav-item"><a class="nav-link" href="https://projects.dotlinkertech.com/frontend/strata/">Features</a></li>
          <li class="nav-item"><router-link class="nav-link" :class="{'activee': $route.path === '/pricing'}" to="/pricing">Pricing</router-link></li>
          <li class="nav-item"><a class="nav-link" href="https://projects.dotlinkertech.com/frontend/strata/resource/">Resource</a></li>
          <li class="nav-item"><a class="nav-link" href="https://projects.dotlinkertech.com/frontend/strata/">About Us</a></li>
          <li class="nav-item"><a class="nav-link" href="https://projects.dotlinkertech.com/frontend/strata/contact-us/">Contact Us</a></li>
          <li class="nav-item"><router-link class="nav-link" to="#">FAQ</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="#">Free Trial</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/login">Member Login</router-link></li>
        </ul>
      </div>
    </div>
  </nav>
  

    <!-- header ends
================================================== --> 
</template>

<script>
export default {
 name: "HeaderPage",
 props: {
   msg: String,
 },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../../public/assets/cssLanding/bootstrap.min.css"></style>
<style scoped src="../../../public/assets/fontsLanding/iconfonts.css"></style>
<style scoped src="../../../public/assets/cssLanding/plugins.css"></style>
<style scoped src="../../../public/assets/cssLanding/style.css"></style>
<style scoped src="../../../public/assets/cssLanding/responsive.css"></style>
<style scoped src="../../../public/assets/cssLanding/color.css"></style>
<style scoped>
.nav-link{
    color: #1e247e !important;
    font-size: 17px;
    font-weight: 600;
    padding: 0px 15px !important;
}
.activee{
  border-bottom: 1px solid #1e247e;
}
@media screen and (max-width: 576px) {
  .navbar{
    padding: 20px !important;
  }
}
</style>
