// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../../../router/index";

const state = {
    // Additional state if needed
    getPolls: '',
    pollById: '',
}

const mutations = {
    SET_POLL_DATA(state, data) {
        state.getPolls = data
    },
    SET_POLL_BY_ID(state, data) {
        state.pollById = data
    },
}

const actions = {
    // Additional actions if needed
      getPoll({ commit }, id) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_POLLS_ADMIN_ID + id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                            "ngrok-skip-browser-warning": "69420",
                        },
                    })
                    .then((res) => {
                        console.log('getPoll---res !!', res)
                        if(Array.isArray(res.data.data)){
                            commit('SET_POLL_DATA', res.data.data)
                        }
                        else{
                           commit('SET_POLL_DATA', res.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('getPoll err', err)
                    })
            // })
    },

    getPollById({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_POLL_BY_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getPollById---res !!', res)
                    if(res.data.data){
                        commit('SET_POLL_BY_ID', res.data.data)
                    }
                    else{
                       commit('SET_POLL_BY_ID', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getPollById err', err)
                })
        // })
    },

    addPoll({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("topic", payload.topic);
        formData.append("invitees[allMembers]", payload.invitees.allMembers === true ? "1" : "0"); 
        formData.append("invitees[AllOwners]", payload.invitees.allOwner === true ? "1" : "0"); 
        formData.append("invitees[AllTenants]", payload.invitees.allTenant === true ? "1" : "0"); 
        formData.append("file", payload.file);
        formData.append("adminId", payload.adminId);
        formData.append("userId", payload.userId);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_POLL, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addPoll res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/all-enquiries")
                    dispatch('getPoll', payload.adminId)
                })
                .catch((err) => {
                    console.log("addPoll err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    editPoll({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("topic", payload.topic);
        formData.append("invitees[allMembers]", payload.invitees.allMembers === true ? "1" : "0"); 
        formData.append("invitees[AllOwners]", payload.invitees.allOwner === true ? "1" : "0"); 
        formData.append("invitees[AllTenants]", payload.invitees.allTenant === true ? "1" : "0"); 
        formData.append("file", payload.file);
        
        return new Promise((resolve, reject) => {
            axios
                .patch(axios.defaults.baseURL + apis.EDIT_POLL_BY_ID + payload.id, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("editPoll res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/my-enquiries")
                    dispatch('getPoll', payload.adminId)
                })
                .catch((err) => {
                    console.log("editPoll err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },


    deletePollById({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_POLL_BY_ID + payload.id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deletePollById---res !!', res)
                    resolve(res)
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getPoll', payload.adminId)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deletePollById err', err)
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                })
        })
    },

    addPollVote({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("vote", payload.vote);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.POLL_VOTES_BY_ID + `${payload.pollId}/vote/${payload.userId}`, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addPollVote res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/all-enquiries")
                    dispatch('getPoll', payload.adminId)
                })
                .catch((err) => {
                    console.log("addPollVote err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },


}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };