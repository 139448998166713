<template>
       <!-- footer section starts
    ================================================== -->
    <footer id="dtr-footer"> 
            
        <!--== footer main starts ==-->
        <div class="dtr-footer-main dtr-box-layout"> 
            
            <!--== row starts ==-->
            <div class="row"> 
                
                <!-- column 1 starts -->
                <div class="col-12 col-md-4 footer-col-1">
                    <a class="logo-alt dtr-scroll-link" href="index.html">
                        <img src="assets/imagesLanding/new-logo.png" alt="logo">                         
                    </a>
                    <p class="mt-3">So, you must be wondering, what is strata management?&nbsp;</p>
                </div>
                <!-- column 1 ends --> 
                
                <div class="col-12 col-md footer-col-2 text-start"> 
                    <ul class="dtr-list-inline d-flex flex-column">
                        <li><a href="https://projects.dotlinkertech.com/frontend/strata/" class="dtr-scroll-link">Home</a></li>
                        <li><a href="https://projects.dotlinkertech.com/frontend/strata/" class="dtr-scroll-link">Features</a></li>
                        <li><router-link to="/pricing" class="dtr-scroll-link">Pricing</router-link></li>
                        <li><a href="https://projects.dotlinkertech.com/frontend/strata/" class="dtr-scroll-link">About Us</a></li>
                    </ul>
                </div>

                <div class="col-12 col-md footer-col-2 text-start"> 
                    <ul class="dtr-list-inline d-flex flex-column">
                        <li><a href="https://projects.dotlinkertech.com/frontend/strata/contact-us/" class="dtr-scroll-link">Contact Us</a></li>
                        <li><a href="#" class="dtr-scroll-link">FAQ</a></li>
                        <li><a href="#" class="dtr-scroll-link">Free Trial</a></li>
                    </ul>
                </div>

                <div class="col-12 col-md-3 footer-col-2 text-start"> 
                    <p>+ 44 641 754 0072</p>
                    <!-- social starts -->
                    <ul class="dtr-social dtr-social-list dtr-mt-20">
                        <li><a href="#" class="dtr-instagram" target="_blank" title="instagram"></a></li>
                        <li><a href="#" class="dtr-twitter" target="_blank" title="twitter"></a></li>
                        <li><a href="#" class="dtr-google" target="_blank" title="google"></a></li>
                        <li><a href="#" class="dtr-skype" target="_blank" title="skype"></a></li>
                    </ul>
                    <!-- social ends --> 
                    
                </div>
                <!-- column 2 ends --> 
                
            </div>
            <!--== row ends ==--> 
            
        </div>
        <!--== footer main ends ==--> 
        
    </footer>
    <!-- footer section ends
================================================== --> 
</template>

<script>
export default {
 name: "FooterPage",
 props: {
   msg: String,
 },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../../public/assets/cssLanding/bootstrap.min.css"></style>
<style scoped src="../../../public/assets/fontsLanding/iconfonts.css"></style>
<style scoped src="../../../public/assets/cssLanding/plugins.css"></style>
<style scoped src="../../../public/assets/cssLanding/style.css"></style>
<style scoped src="../../../public/assets/cssLanding/responsive.css"></style>
<style scoped src="../../../public/assets/cssLanding/color.css"></style>

<style scoped>
#dtr-footer{
  
}
.dtr-footer-main {
    background-color: #353535;
    padding: 100px 130px;
    margin: 0;
}
.dtr-footer-main, .dtr-footer-main a{
    color: white;
    font-size: 18px;
}
.dtr-footer-main:hover, .dtr-footer-main a:hover{
    color: white;
}
p{
    font-size: 18px;
    line-height: 40px;
}
.dtr-list-inline li{
    margin-bottom: 10px;
}
@media screen and (max-width: 576px) {
    .dtr-footer-main{
      padding: 40px;
    }
    .dtr-footer-main, .dtr-footer-main a{
        font-size: 15px;
    }
    p{
        font-size: 15px;
    }
  }
</style>
