// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../../../router/index";

const state = {
    // Additional state if needed
    getPinboard: '',
    pinboardById: '',
}

const mutations = {
    SET_PINBOARD_DATA(state, data) {
        state.getPinboard = data
    },
    SET_PINBOARD_BY_ID(state, data) {
        state.pinboardById = data
    },
}

const actions = {
    // Additional actions if needed
    getPinboard({ commit }, id) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_PINBOARD_ADMIN_ID + id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                            "ngrok-skip-browser-warning": "69420",
                        },
                    })
                    .then((res) => {
                        console.log('getPinboard---res !!', res)
                        if(Array.isArray(res.data.data)){
                            commit('SET_PINBOARD_DATA', res.data.data)
                        }
                        else{
                           commit('SET_PINBOARD_DATA', res.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('getPinboard err', err)
                        // dispatch("checkErrorAndSendToast", [
                        //     err?.response,
                        //     "error",
                        //   ]);
                    })
            // })
    },

    getPinboardById({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_PINBOARD_BY_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getPinboardById---res !!', res)
                    if(Array.isArray(res.data.data)){
                        commit('SET_PINBOARD_BY_ID', res.data.data)
                    }
                    else{
                       commit('SET_PINBOARD_BY_ID', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getPinboardById err', err)
                })
        // })
    },

    addPinboard({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("adminId", payload.adminId);
        formData.append("userId", payload.userId);
        formData.append("caption", payload.caption);
        formData.append("image", payload.image);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_PINBOARD, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addPinboard res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/all-enquiries")
                    dispatch('getPinboard', payload.adminId)
                })
                .catch((err) => {
                    console.log("addPinboard err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    editPinboard({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("caption", payload.caption);
        formData.append("image", payload.image);
        
        return new Promise((resolve, reject) => {
            axios
                .put(axios.defaults.baseURL + apis.EDIT_PINBOARD_BY_ID + payload.id, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("editPinboard res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/my-enquiries")
                    dispatch('getPinboard', payload.adminId)
                })
                .catch((err) => {
                    console.log("editPinboard err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },


    deletePinboardById({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_PINBOARD_BY_ID + payload.id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deletePinboardById---res !!', res)
                    resolve(res)
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getPinboard', payload.adminId)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deletePinboardById err', err)
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                })
        })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };