<!-- AdminLayout.vue -->
<template>
    <div>
      <div class="container-scroller">
        <Header @toggleSidebar="toggleSidebar" />
        <div class="container-fluid page-body-wrapper">
          <Sidebar :isOpen="isSidebarOpen" />
          <div class="main-panel">
            <router-view></router-view>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Header from "../components/superAdminLayout/Header.vue";
  import Sidebar from "../components/superAdminLayout/Sidebar.vue";
  import Footer from "../components/superAdminLayout/Footer.vue";
  
  export default {
    name: "SuperAdminLayout",
    components: { Header, Sidebar, Footer },
    data() {
      return {
        isSidebarOpen: false,
      };
    },
    methods: {
      toggleSidebar() {
        this.isSidebarOpen = !this.isSidebarOpen;
      },
    },
    mounted() {},
  };
  </script>
  