// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../../../router/index";

const state = {
    // Additional state if needed
    getVenues: '',
    venueById: '',
    getVenueBookedByFilter: '',
    getVenueBookings: '',
    getVenueUserBookings: '',
    bookingMessageData: '',
}

const mutations = {
    SET_VENUES_DATA(state, data) {
        state.getVenues = data
    },
    SET_VENUE_BY_ID(state, data) {
        state.venueById = data
    },
    SET_FILTER_BOOKED_VENUE_DATA(state, data) {
        state.getVenueBookedByFilter = data
    },
    SET_VENUE_BOOKING_DATA(state, data) {
        state.getVenueBookings = data
    },
    SET_VENUE_USER_BOOKING_DATA(state, data) {
        state.getVenueUserBookings = data
    },
    SET_MESSAGE_DATA(state, data) {
        state.bookingMessageData = data
    },
}

const actions = {
    // Additional actions if needed
      getVenues({ commit }, id) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_VUENUES_ADMIN_ID + id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                            "ngrok-skip-browser-warning": "69420",
                        },
                    })
                    .then((res) => {
                        console.log('getVenue---res !!', res)
                        if(Array.isArray(res.data.data)){
                            commit('SET_VENUES_DATA', res.data.data)
                        }
                        else{
                           commit('SET_VENUES_DATA', res.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('getVenue err', err)
                        // dispatch("checkErrorAndSendToast", [
                        //     err?.response,
                        //     "error",
                        //   ]);
                    })
            // })
    },

    getVenueById({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_VUENUE_BY_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getVenueById---res !!', res)
                    if(res.data.data){
                        commit('SET_VENUE_BY_ID', res.data.data)
                    }
                    else{
                       commit('SET_VENUE_BY_ID', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getVenueById err', err)
                })
        // })
    },

    addVenue({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("adminId", payload.adminId);
        formData.append("name", payload.name);
        formData.append("location", payload.location);
        formData.append("openingTime", payload.openingTime);
        formData.append("capacity", payload.capacity);
        formData.append("closingTime", payload.closingTime);
        formData.append("openingDays", payload.openingDays);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_VENUE, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addVenue res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/all-enquiries")
                    dispatch('getVenues', payload.adminId)
                })
                .catch((err) => {
                    console.log("addVenue err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    editVenue({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("name", payload.name);
        formData.append("location", payload.location);
        formData.append("openingTime", payload.openingTime);
        formData.append("capacity", payload.capacity);
        formData.append("closingTime", payload.closingTime);
        formData.append("openingDays", payload.openingDays);
        
        return new Promise((resolve, reject) => {
            axios
                .patch(axios.defaults.baseURL + apis.EDIT_VENUE_BY_ID + payload.id, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("editVenue res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/my-enquiries")
                    dispatch('getVenues', payload.adminId)
                })
                .catch((err) => {
                    console.log("editVenue err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },


    deleteVenueById({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_VENUE_BY_ID + payload.id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deleteVenueById---res !!', res)
                    resolve(res)
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getVenues', payload.adminId)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteVenueById err', err)
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                })
        })
    },



    // Check availability

    filterBookingVenue({ commit }, payload) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_VENUE_BOOKING_FILTER + `${payload.id}/bookings/date/${payload.date}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('filterBookingVenue---res !!', res)
                    if(Array.isArray(res.data.data)){
                        commit('SET_FILTER_BOOKED_VENUE_DATA', res.data.data)
                    }
                    else{
                       commit('SET_FILTER_BOOKED_VENUE_DATA', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('filterBookingVenue err', err)
                })
        // })
    },


    addVenueBooking({commit, dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("venueId", payload.venueId);
        formData.append("userId", payload.userId);
        formData.append("date", payload.venueDate);
        formData.append("timeslot", payload.timeslot.start + " - " + payload.timeslot.end);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_VENUE_BOOKING, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addVenueBooking res", res);
                    resolve(res);
                    if(res.data.status === 'info'){
                        commit('SET_MESSAGE_DATA', res.data)
                    }else{
                        dispatch("checkErrorAndSendToast", [
                            res.data.message,
                            "success",
                        ]);
                    }
                    // router.push("/all-enquiries")
                    dispatch('filterBookingVenue', {id: payload.id, date: payload.venueDate} )
                })
                .catch((err) => {
                    console.log("addVenueBooking err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },


    getVenueUserBookings({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_VENUE_USER_BOOKINGS + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getVenueUserBookings---res !!', res)
                    if(Array.isArray(res.data.data)){
                        commit('SET_VENUE_USER_BOOKING_DATA', res.data.data)
                    }
                    else{
                       commit('SET_VENUE_USER_BOOKING_DATA', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getVenueUserBookings err', err)
                })
        // })
    },

    deleteVenueByUserId({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_VENUE_USER_BOOKINGS + `${payload.venueId}/booking/${payload.bookingId}/user/${payload.userId}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deleteVenueByUserId---res !!', res)
                    resolve(res)
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getVenueUserBookings', payload.id)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteVenueByUserId err', err)
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                })
        })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };