import axios from "axios";
import router from "@/router/index";

export default function auth({ to, from, next, store }) {
  var token = localStorage.getItem("token");
  var isAuthenticated = Boolean(token);
  console.log("isAuthenticated from--", from);
  // console.log("isAuthenticated middelware--", isAuthenticated);
  // console.log("isAuthenticated store--", store);
  // console.log("isAuthenticated next--", next);
  // console.log("isAuthenticated to--", to);
  // console.log("isAuthenticated meta.portal--", to.meta.portal);

  if (isAuthenticated) {
    if (store.getters.auth) {
      //console.log('authenticate');
      next();
    } else {
      axios
        .get(axios.defaults.baseURL + "auth/current-user", {
          headers: {
            Authorization: "Bearer " + token,
            "ngrok-skip-browser-warning": "69420",
          },
        })
        .then((response) => {
          console.log("login details--from auth middleware--", response);

          if(response.data.data[0].role[0] === 'SAD'){
            let user = {
              admin: response.data.data[0].admin,
              createdAt: response.data.data[0].createdAt,
              email: response.data.data[0].email,
              firstName: response.data.data[0].firstName,
              profileLink: response.data.data[0].profileLink,
              lastName: response.data.data[0].lastName,
              role: response.data.data[0].role[0],
              status: response.data.data[0].status,
              updatedAt: response.data.data[0].updatedAt,
              _id: response.data.data[0]._id,
              token: token,
            };
            store.commit("SET_LOGIN_DETAILS", user);
            store.commit("SET_USER_AUTHS", {isLoggedIn: true, token: token});
            store.commit("SET_COMPONENT_LAYOUT", { layout: "SuperAdminLayout" });
            localStorage.setItem("user", JSON.stringify(user));
          }
          else{
            let user = {
              admin: response.data.data[0].admin,
              alternativeEmail: response.data.data[0].alternativeEmail,
              createdAt: response.data.data[0].createdAt,
              email: response.data.data[0].email,
              firstName: response.data.data[0].firstName,
              profileLink: response.data.data[0].profileLink,
              lastName: response.data.data[0].lastName,
              member: response.data.data[0].member,
              role: response.data.data[0].role,
              status: response.data.data[0].status,
              subscriptionStatus: response.data.data[0].subscriptionStatus,
              updatedAt: response.data.data[0].updatedAt,
              _id: response.data.data[0]._id,
              token: token,
            };
            store.commit("SET_LOGIN_DETAILS", user);
            store.commit("SET_USER_AUTHS", {isLoggedIn: true, token: token});
            store.commit("SET_COMPONENT_LAYOUT", { layout: "AdminLayout" });
            localStorage.setItem("user", JSON.stringify(user));
           
              // router.push("/admin")
          
          }

          next();
        })
        .catch((err) => {
          console.log("error hai routes--", err);
          if ("message" in err) {
            alert(err.message);
          }
          if (err.response.statusText == "Unauthorized") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            return next({
              name: "Login",
            });
          }
        });
    }
  } else if (to.meta.portal === "Admin"){
    store.commit("SET_USER_AUTHS", { token: null, isLoggedIn: false });
    store.commit("SET_COMPONENT_LAYOUT", { layout: "AdminLayout" });
    console.log("pahonch gaya")
    // return next({
    //   name: "Login",
    // });
    router.push("/login")
  } 
  else if(to.meta.portal === "SuperAdmin") {
    store.commit("SET_USER_AUTHS", { token: null, isLoggedIn: false });
    // return next({
    //   name: "Login",
    // });
    router.push("/login")
  }


}
