// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../../../router/index";

const state = {
    // Additional state if needed
    getAllFolders: '',
    getFolderById: '',
}

const mutations = {
    SET_FOLDER_DATA(state, data) {
        state.getAllFolders = data
    },
    SET_FOLDER_BY_ID(state, data) {
        state.getFolderById = data
    },
}

const actions = {
    // Additional actions if needed
    getAllFolders({ commit }, id) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_ALL_FOLDERS + id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                            "ngrok-skip-browser-warning": "69420",
                        },
                    })
                    .then((res) => {
                        console.log('getAllFolders---res !!', res)
                        if(Array.isArray(res.data.data)){
                            commit('SET_FOLDER_DATA', res.data.data)
                        }
                        else{
                           commit('SET_FOLDER_DATA', res.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('getAllFolders err', err)
                        // dispatch("checkErrorAndSendToast", [
                        //     err?.response,
                        //     "error",
                        //   ]);
                    })
            // })
    },

    getFolderById({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_FOLDER_BY_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getFolderById---res !!', res)
                    if(res.data.data){
                        commit('SET_FOLDER_BY_ID', res.data.data)
                    }
                    else{
                       commit('SET_FOLDER_BY_ID', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getFolderById err', err)
                })
        // })
    },

    createFolder({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("name", payload.name);
        formData.append("parentFolder", payload.parentFolder);
        formData.append("adminId", payload.adminId);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.CREATE_FOLDER, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("createFolder res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);

                    dispatch('getAllFolders', payload.adminId)
                })
                .catch((err) => {
                    console.log("createFolder err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    createDocument({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("name", payload.name);
        formData.append("size", payload.size);
        formData.append("file", payload.file);
        formData.append("folder", payload.folder);
        formData.append("adminId", payload.adminId);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.CREATE_DOCUMENT, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("createDocument res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);

                    dispatch('getAllFolders', payload.adminId)
                })
                .catch((err) => {
                    console.log("createDocument err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    renameFolder({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("name", payload.name);
        
        return new Promise((resolve, reject) => {
            axios
                .patch(axios.defaults.baseURL + apis.RENAME_FOLDER_BY_ID + payload.folderId, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("renameFolder res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                   
                    dispatch('getAllFolders', payload.adminId)
                })
                .catch((err) => {
                    console.log("renameFolder err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },


    deleteFolderById({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_FOLDER_BY_ID + payload.id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deleteFolderById---res !!', res)
                    resolve(res)
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                     
                    dispatch('getAllFolders', payload.adminId)
                    dispatch('getFolderById', payload.folderId)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteFolderById err', err)
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                })
        })
    },

    deleteDocumentById({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_DOCUMENT_BY_ID + payload.id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deleteDocumentById---res !!', res)
                    resolve(res)
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                     
                    dispatch('getAllFolders', payload.adminId)
                    dispatch('getFolderById', payload.folderId)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteDocumentById err', err)
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                })
        })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };