// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from "./apis/auth";
import router from "../router/index";

const state = {
  // Additional state if needed
  user: null,
  token: null,
  adminDetailsByID: "",
  isLoggedIn: false,
  componentLayout: "DriverLayout",
  getAdmins: "",
};

const mutations = {
  SET_LOGIN_DETAILS(state, data) {
    console.log("user set", data);
    state.user = data;
  },
  SET_ADMIN_DETAILS_BY_ID(state, data) {
    console.log("adminDetailsByID set", data);
    state.adminDetailsByID = data;
  },
  SET_USER_AUTHS(state, data) {
    console.log("SET_USER_AUTHS set", data);
    state.isLoggedIn = data.isLoggedIn;
    state.token = data.token;
  },
  SET_COMPONENT_LAYOUT(state, data) {
    console.log("SET_COMPONENT_LAYOUT set", data);
    state.componentLayout = data.layout;
  },
  SET_ADMINS_DATA(state, data) {
    state.getAdmins = data;
  },

  // LOGOUT_USER(state, data) {
  //   state.user = data;
  //   state.token = data;
  // },
};

const actions = {
  // Additional actions if needed
  login({ dispatch, commit }, payload) {
    console.log("payload--", payload);

    var formData = new FormData();
    formData.append("email", payload.email);
    formData.append("password", payload.password);

    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.LOGIN, formData)
        .then((res) => {
          console.log("login res--", res);
          resolve(res);
          // var formData2 = new FormData();
          // formData2.append("token", res.data.data.accessToken);
          axios
            .get(axios.defaults.baseURL + apis.GET_CURRENT_LOGGED_IN_USER, {
              headers: {
                Authorization: "Bearer " + res.data.data.accessToken,
                "ngrok-skip-browser-warning": "69420",
              },
            })
            .then((response) => {
              console.log("login details-- auth--", response);
              localStorage.setItem("token", res.data.data.accessToken);
              localStorage.setItem(
                "user",
                JSON.stringify(response.data.data[0])
              );

              if (response.data.data[0].role[0] === "SAD") {
                let user = {
                  admin: response.data.data[0].admin,
                  createdAt: response.data.data[0].createdAt,
                  email: response.data.data[0].email,
                  firstName: response.data.data[0].firstName,
                  profileLink: response.data.data[0].profileLink,
                  lastName: response.data.data[0].lastName,
                  role: response.data.data[0].role[0],
                  status: response.data.data[0].status,
                  updatedAt: response.data.data[0].updatedAt,
                  _id: response.data.data[0]._id,
                  token: res.data.data.accessToken,
                };
                commit("SET_LOGIN_DETAILS", user);
                commit("SET_USER_AUTHS", {
                  isLoggedIn: true,
                  token: res.data.data.accessToken,
                });
                commit("SET_COMPONENT_LAYOUT", { layout: "SuperAdminLayout" });
              } else {
                let user = {
                  admin: response.data.data[0].admin,
                  alternativeEmail: response.data.data[0].alternativeEmail,
                  createdAt: response.data.data[0].createdAt,
                  email: response.data.data[0].email,
                  firstName: response.data.data[0].firstName,
                  profileLink: response.data.data[0].profileLink,
                  lastName: response.data.data[0].lastName,
                  member: response.data.data[0].member,
                  role: response.data.data[0].role,
                  status: response.data.data[0].status,
                  subscriptionStatus: response.data.data[0].subscriptionStatus,
                  updatedAt: response.data.data[0].updatedAt,
                  _id: response.data.data[0]._id,
                  token: res.data.data.accessToken,
                };
                commit("SET_LOGIN_DETAILS", user);
                commit("SET_USER_AUTHS", {
                  isLoggedIn: true,
                  token: res.data.data.accessToken,
                });
                commit("SET_COMPONENT_LAYOUT", { layout: "AdminLayout" });
              }

              if (response.data.data[0].role[0] === "SAD") {
                router.push("/super-admin").then(() => {
                  router.go();
                });
              } else {
                router.push("/admin").then(() => {
                  router.go();
                });
              }
              //   } else if (response.data[0].roles == 2) {
              //     router.push("/company").then(() => {
              //       router.go();
              //     });
              //   } else {
              //     router.push("/")
              //   }
              // router.push("/").go()
              dispatch("checkErrorAndSendToast", [res.data.message, "success"]);
            })
            .catch((err) => {
              console.log("error ff hai--", err);

              localStorage.removeItem("token");
              localStorage.removeItem("user");
              // router.go();
            });
        })
        .catch((err) => {
          console.log("login err", err);
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
          reject(err);

          // if(err.response.data.data.subscriptionStatus === "Expired" && err.response.data.data.role === "AD"){
          //   router.push("/pricing")
          // }
        });
    });
  },

  adminRegister({ dispatch }, payload) {
    console.log("payload--", payload);
    // return
    var formData = new FormData();
    formData.append("firstName", payload.firstName);
    formData.append("lastName", payload.lastName);
    formData.append("email", payload.email);
    formData.append("password", payload.password);
    formData.append("title", payload.title);
    formData.append("phoneNumbers", payload.phoneNumbers);
    // formData.append("unit", payload.unit);

    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.ADMIN_REGISTER, formData)
        .then((res) => {
          console.log("ADMIN_COMPLETE_RESISTRATION res", res);
          // localStorage.setItem("token", res.data.data.token);
          router.push("/login").then(() => {
            router.go();
          });

          resolve(res);
          dispatch("checkErrorAndSendToast", [res, "success"]);
        })
        .catch((err) => {
          console.log("ADMIN_COMPLETE_RESISTRATION err", err);
          reject(err);
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
        });
    });
  },
  adminCompleteRegister({ dispatch }, payload) {
    console.log("payload-adminCompleteRegister-", payload);
    console.log("payload-payload[0]?.address-", payload[0]?.address);

    // return

    var formdata;
    if (payload[1] == "FirstStep") {
      console.log('if---');
      const address = payload[0]?.address;

      formdata = {
        userId: payload[0]?.userId,
        phoneNumbers: payload[0]?.phoneNumbers,
        address: {
          address1: address?.address1,
          address2: address?.address2,
          city: address?.city,
          state: address?.state,
          postcode: address?.postcode,
          country: address?.country,
        },
        corporationType: "",
        registrationNumber: "",
        propertyName: "",
        abn: "",
        registeredAddress: {
          address1: "",
          address2: "",
          city: "",
          state: "",
          postCode: "",
          timezone: "",
        },
      };
    }
    else{
      console.log('else---');
      
      const registeredAddress = payload[0]?.registeredAddress;
      const address = payload[0]?.AdminDetailsByID?.address;
      const stepOne = payload[0]?.AdminDetailsByID

      formdata = {
        userId: payload[0]?.userId,
        phoneNumbers: stepOne?.phoneNumbers,
        address: {
          address1: address?.address1,
          address2: address?.address2,
          city: address?.city,
          state: address?.state,
          postcode: address?.postcode,
          country: address?.country,
        },
        corporationType: payload[0]?.corporationType,
        registrationNumber: payload[0]?.registrationNumber,
        propertyName: payload[0]?.propertyName,
        abn: payload[0]?.abn,
        registeredAddress: {
          address1: registeredAddress?.address1,
          address2: registeredAddress?.address2,
          city: registeredAddress?.city,
          state: registeredAddress?.state,
          postCode: registeredAddress?.postCode,
          timezone: registeredAddress?.timezone,
        },
      };
    }

    return new Promise((resolve, reject) => {
      axios
        .post(
          axios.defaults.baseURL + apis.ADMIN_COMPLETE_RESISTRATION,
          formdata
        )
        .then((res) => {
          console.log("ADMIN_COMPLETE_RESISTRATION res", res);
          // localStorage.setItem("token", res.data.data.token);
          // router.push("/login").then(() => {
          //   router.go();
          // });

          resolve(res);
          dispatch("checkErrorAndSendToast", [res.data.message, "success"]);
        })
        .catch((err) => {
          console.log("ADMIN_COMPLETE_RESISTRATION err", err);
          reject(err);
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
        });
    });
  },

  memberRegister({ dispatch }, payload) {
    console.log("payload--", payload);
    // return
    var formData = new FormData();
    formData.append("firstName", payload.firstName);
    formData.append("lastName", payload.lastName);
    formData.append("profile", payload.profile);
    formData.append("email", payload.email);
    formData.append("role", payload.role);
    formData.append("password", payload.password);
    formData.append("title", payload.title);
    formData.append("phoneNumbers", payload.phoneNumbers);
    formData.append("address[address1]", payload.address.address1);
    formData.append("address[address2]", payload.address.address2);
    formData.append("address[city]", payload.address.city);
    formData.append("address[state]", payload.address.state);
    formData.append("address[postcode]", payload.address.postcode);
    formData.append("address[country]", payload.address.country);
    formData.append("unitId", payload.unitId);
    formData.append("adminId", payload.adminId);

    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.MEMBER_REGISTER, formData)
        .then((res) => {
          console.log("adminRegister res", res);
          // localStorage.setItem("token", res.data.data.token);
          router.push("/login").then(() => {
            router.go();
          });

          resolve(res);
          dispatch("checkErrorAndSendToast", [res, "success"]);
        })
        .catch((err) => {
          console.log("adminRegister err", err);
          reject(err);
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
        });
    });
  },

  getAdminByID({ commit }, id) {
    axios
      .get(axios.defaults.baseURL + "admin/" + id, {})
      .then((res) => {
        console.log("getAdmins---res !!", res);
        if (Array.isArray(res.data.data)) {
          commit("SET_ADMIN_DETAILS_BY_ID", res.data.data[0]);
        } else {
          commit("SET_ADMIN_DETAILS_BY_ID", res.data.message);
        }
      })
      .catch((err) => {
        console.log("getAdmins err", err);
      });
  },
  getAdmins({ commit }) {
    // return
    // return new Promise((resolve, reject) => {
    axios
      .get(axios.defaults.baseURL + "admin", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "ngrok-skip-browser-warning": "69420",
        },
      })
      .then((res) => {
        console.log("getAdmins---res !!", res);
        if (Array.isArray(res.data.data)) {
          commit("SET_ADMINS_DATA", res.data.data);
        } else {
          commit("SET_ADMINS_DATA", res.data.message);
        }
      })
      .catch((err) => {
        console.log("getAdmins err", err);
        // dispatch("checkErrorAndSendToast", [
        //     err?.response,
        //     "error",
        //   ]);
      });
    // })
  },

  logout({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(axios.defaults.baseURL + apis.LOGOUT, {
          headers: {
            Authorization: "Bearer " + payload.userToken,
          },
        })
        .then((res) => {
          resolve(res);

          console.log("logout res", res);

          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("superAdminToken");
          localStorage.removeItem("adminToken");

          if (payload.name == "admin") {
            router.push("/login").then(() => {
              router.go();
            });
          }
          dispatch("checkErrorAndSendToast", [res.data.message, "success"]);
        })
        .catch((err) => {
          reject(err);
          console.log("logout err", err);
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
        });
    });
  },

  forgotPassword({ dispatch }, payload) {
    var formData = new FormData();
    formData.append("email", payload.email);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.FORGOT_PASSWORD, formData)
        .then((res) => {
          console.log("forgotPassword res", res);

          resolve(res);
          dispatch("checkErrorAndSendToast", [res, "success"]);
        })
        .catch((err) => {
          console.log("forgotPassword err", err);
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
          reject(err);
        });
    });
  },

  resetPassword({ dispatch }, payload) {
    console.log(payload, "pay");
    var formData = new FormData();
    formData.append("token", payload.id);
    formData.append("password", payload.password);
    formData.append("c_password", payload.confirm_password);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.RESET_PASSWORD, formData)
        .then((res) => {
          console.log("resetPassword res", res);
          router.push("/").then(() => {
            router.go();
          });

          resolve(res);
          dispatch("checkErrorAndSendToast", [res, "success"]);
        })
        .catch((err) => {
          console.log("resetPassword err", err);
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
          reject(err);
        });
    });
  },
};

const getters = {
  // Additional getters if needed
  auth(state) {
    return state.isLoggedIn;
  },
  Layout(state) {
    return state.componentLayout;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
