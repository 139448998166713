<!-- AdminLayout.vue -->
<template>
  <div>
    <div class="container-scroller">
      <Header @toggleSidebar="toggleSidebar" />
      <div class="container-fluid page-body-wrapper">
        <Sidebar :isOpen="isSidebarOpen" />
        <div class="main-panel">
          <router-view></router-view>
          <Footer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/adminLayout/Header.vue";
import Sidebar from "../components/adminLayout/Sidebar.vue";
import Footer from "../components/adminLayout/Footer.vue";

export default {
  name: "AdminLayout",
  components: { Header, Sidebar, Footer },
  data() {
    return {
      isSidebarOpen: false,
    };
  },
  watch: {
    // Watch the route for changes
    $route() {
      // Close the sidebar on route change
      this.isSidebarOpen = false;
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
  mounted() {},
};
</script>
